<template>
  <Dialog ref="dialog" :config="config" :visible='visible' :cancelDialog="cancelDialog" :confirmDialog='confirmDialog' :isdeling='isdeling'>
    <p class="tips">{{msg}}</p>
    <p class="tips top" v-if="explain != ''"> {{explain}} </p>
  </Dialog>
</template>

<script>
  import Dialog from "@/components/Dialog";

  export default {
    props: {
      msg: {
        type: String
      },
      explain: {
        type: String,
        default: () => { return '' }
      },
      visible: {
        type: Boolean,
      },
      isdeling:{
        type: Boolean,
      },
      confirmDialog: {
        type: Function,
        default: () => { },
      },
      cancelDialog:{
        type: Function,
        default: () => { },
      }
    },
    components: {
      Dialog,
    },
    data() {
      return {
        config: {
          top: '28vh',
          width: '21rem',
          title: '提示',
          center: true,
          btnTxt: ['确定','取消'],
        },
      };
    },
    methods: {
      // cancelDialog(){
      //   this.confirmDialog();
      // }
    }
  };
</script>

<style lang="less" scoped>
  .tips {
    text-align: center;
    margin-bottom: 0.8rem;
    margin-top: 2rem;
    line-height: 24px;
    font-size: 0.83rem;
  }
  .top {
    margin-top: 0;
  }
</style>